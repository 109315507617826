html,
body,
#root {
  margin: 0;
  height: 100%;
  width: 100%;

  font-family: 'Maven Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #323131;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
}

#root:after {
  z-index: -1;
  content: '';
  margin: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0.4;
  background: url(https://visitcaliforniaengagement.com/images/map-bg.png?v5)
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

html {
  font-size: 16px;
}

h1 {
  text-transform: uppercase;
  color: #e15727;
  font-size: 18pt;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper__clients {
  max-width: 1024px;
  margin: 0 auto;
}

.header {
  max-width: 1024px;
  margin: 0 auto;
  font-size: 14pt;
  margin-bottom: 2rem;
  text-align: center;
  padding-top: 7rem;
  padding-right: 4rem;
  padding-left: 4rem;

  .head {
    padding-bottom: 3rem;
    font-weight: bold;
    font-size: 18pt;
    text-align: center;
    color: #e15727;
  }

  hr {
    width: 4rem !important;
    margin-top: 2rem;
  }
}

.comingSoon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 1rem;

  img {
    image-rendering: -webkit-optimize-contrast;
    max-height: 150px;
    width: auto;
    border-radius: 20px;
    box-shadow: 0px 0px 8px -4px rgba(0, 0, 0, 0.44);
  }
}

.socials {
  position: fixed;
  right: 0;
  top: 30%;
  display: grid;
  background: #e15727;
  padding: 0.2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-radius: 10px 0px 0px 10px;
  -webkit-box-shadow: 0px 0px 28px -4px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 0px 0px 28px -4px rgba(0, 0, 0, 0.44);
  box-shadow: 0px 0px 28px -4px rgba(0, 0, 0, 0.44);
  z-index: 9999;

  .title {
    color: white;
    width: 4rem;
    float: right;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1rem;
    color: white;
    font-size: 11pt;
  }

  a {
    color: white;
    font-size: 1.8rem;
    margin-bottom: 1rem;
    margin-left: 1.3rem;
  }
}

.featured-header {
  margin-top: 3rem;
}

.featured {
  display: grid;
  height: 100%;
  width: auto;
  grid-gap: 2rem;
  margin: 1rem auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .wrapper__card .name {
    color: #f57f20 !important;
  }
  .wrapper__card {
    box-shadow: 0px 0px 8px -4px rgba(14, 92, 160, 1);

    .wrapper__overlay {
      .name {
        color: #f57f20 !important;
      }

      .image {
        object-fit: cover;
        object-position: center;
        height: 10rem;
      }
    }
  }
}

.featured__text {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.wrapper__clients {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  width: 100%;

  .container__clients {
    display: grid;
    height: 100%;
    width: 100%;

    margin: 0 auto;

    grid-template-columns: repeat(5, 1fr);

    .header__clients {
      grid-column: span 5;
    }

    @media only screen and (max-width: 70.9375em) {
      grid-template-columns: repeat(3, 1fr);

      .header__clients {
        grid-column: span 3;
      }
    }

    @media only screen and (max-width: 52.8125em) {
      grid-template-columns: repeat(2, 1fr);

      .header__clients {
        grid-column: span 2;
      }
    }

    @media only screen and (max-width: 35em) {
      grid-template-columns: repeat(1, 1fr);

      .header__clients {
        grid-column: span 1;
      }
    }

    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
}

@media only screen and (max-width: 35em) {
  .featured {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
}

// Groove custom widget
.grv-widget-tag-bottom-right {
  display: block !important;
}

#openWidget {
  width: 10rem;
  height: 3rem;
  background: #e15727;
  padding: 0.3rem;
  text-align: center;
  font-weight: 900;
  color: #fff;
  font-family: arial;
  font-size: 10pt;
  position: fixed;
  bottom: 30px;
  right: 1rem;
  border: 3px solid white;
  border-radius: 0.5rem;
  cursor: pointer;
  display: block;
  z-index: 999;
  -webkit-box-shadow: 0px 0px 28px -4px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 0px 0px 28px -4px rgba(0, 0, 0, 0.44);
  box-shadow: 0px 0px 28px -4px rgba(0, 0, 0, 0.44);
}

#openWidget:focus {
  outline: none;
}

#openWidget:after {
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid #e15727;
  border-top: 10px solid #e15727;
  border-bottom: 10px solid transparent;
  right: 19px;
  bottom: -20px;
  cursor: pointer;
}

#openWidget .widgetBoxArrow:hover:after {
  border-right: 10px solid darken(#e15727, 40%);
  border-top: 10px solid darken(#e15727, 40%);
  transition: 0.5s;
}

#openWidget:hover {
  background: darken(#e15727, 40%);
  transition: 0.5s;
}

.grv-widget-tag-bottom-right {
  bottom: -55px !important;
  right: -55px !important;
}

.embedsocial-hashtag {
  width: 100%;
  height: auto;
  margin-bottom: 3rem;

  iframe {
    height: 20vw !important;
  }
}

@media only screen and (max-width: 35em) {
  .container {
    width: 100%;
    padding-left: 20px;
    padding-right: 25px;
    box-sizing: border-box;
    position: relative;
  }

  .socials {
    position: relative;
    right: auto;
    top: 1rem;
    width: 80%;
    display: grid;
    background: #e15727;
    padding: 0.2rem;
    padding-top: 1rem;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 28px -4px rgba(0, 0, 0, 0.44);
    z-index: 9999;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 3rem;
    padding-left: 1rem;
    padding-right: 2rem;
    .title {
      color: white;
      width: 100%;
      float: right;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 1rem;
      color: white;
      font-size: 11pt;
    }

    a {
      color: white;
      font-size: 1.6rem;
      margin-bottom: auto;
      margin-top: 0.2rem;
      margin-left: 1.3rem;
    }
  }

  .socialHeader {
    display: none;
  }

  .embedsocial-hashtag {
    width: 100%;
    height: 350px !important;
    margin-bottom: 3rem;
    display: none;

    iframe {
      height: 350px !important;
    }
  }

  .header {
    padding-left: 0;
    padding-right: 0;
  }

  #openWidget {
    display: none;
  }

  .wrapper__clients {
    margin-bottom: 0rem;
  }
}

.source {
  position: relative;
  width: 18rem;
  height: auto;
  margin-bottom: 2rem;
}

.top-header {
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 0px 8px -4px rgba(0, 0, 0, 0.44);
  z-index: 9999;
  display: grid;

  img {
    max-width: 10rem;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 450px) {
  .source {
    width: calc(90% - 5rem);
  }

  .wrapper__card .wrapper__overlay .image {
    object-position: center;
  }
}

.aar {
  margin-top: 2rem;
  margin-top: 2rem;
  object-fit: contain;
  width: 100%;
  img {
    margin-top: 2rem;
  }
}
