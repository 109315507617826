.wrapper__card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  position: relative;
  text-align: center;
  background: white;
  box-shadow: 0px 0px 8px -4px rgba(0, 0, 0, 0.44);
  z-index: 999;
  padding: 0.5rem;

  .name {
    margin-top: 0.5rem;
    margin-top: 0.5rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.01rem;
    font-size: 1.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: #0e5ca0;
    padding-bottom: 0.7rem;
  }

  &.unloaded {
    pointer-events: none;
  }

  .wrapper__overlay {
    position: relative;
    margin-bottom: 4rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-height: 164px;

    img {
      transition: opacity 250ms ease-in-out;
      width: 100%;
    }

    .image {
      object-fit: cover;
      object-position: top;
      height: 7.5rem;
    }

    .icon {
      opacity: 1;
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 4.6rem;
      border-radius: 10px;
      -webkit-box-shadow: 0px 0px 28px -4px rgba(0, 0, 0, 0.44);
      -moz-box-shadow: 0px 0px 28px -4px rgba(0, 0, 0, 0.44);
      box-shadow: 0px 0px 28px -4px rgba(0, 0, 0, 0.44);
    }

    .wrapper__loader {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;
    }

    .overlay__card {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      position: absolute;
      top: 0;
      left: 0;

      height: 100%;
      width: 100%;

      background-color: rgba(black, 0.5);
      opacity: 0;
      transition: all 250ms ease-in-out;

      .wrapper__icons {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;

        padding-bottom: 0.3rem;
        position: absolute;

        transition: all 250ms ease-in-out;

        a {
          display: flex;
          justify-content: center;
          align-items: center;

          height: 1rem;
          width: 1rem;
          padding: 0.5rem;

          background-color: whitesmoke;

          text-decoration: none;
          color: black;

          border-radius: 5rem;
        }
      }

      &:hover {
        .wrapper__icons {
          padding-bottom: 0;
        }
        .name__client {
          height: 2rem;
        }
      }

      a {
        transition: color 250ms ease-in-out;
      }

      a:hover {
        color: $travpro-orange;
      }
    }

    .name__client {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      text-align: left;
      overflow: hidden;
      height: 0;
      background-color: white;
      transition: height 250ms ease-in-out;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 5px;
      text-transform: uppercase;

      a {
        margin-left: 0.4rem;
      }

      a:hover {
        color: $travpro-orange;
      }
    }

    &:hover {
      .overlay__card {
        opacity: 1;
      }
    }
  }

  .wrapper__mobile {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: auto auto auto;
    max-width: 10.2rem;
    position: absolute;
    bottom: 1rem;

    .coming__soon {
      font-weight: bold;
      text-transform: uppercase;
    }

    .btn__client {
      flex: 2 1;
      border: 1px solid #e15727;
      padding: 10px;
      border-radius: 0.5rem;
      margin-top: 10px;
      text-transform: uppercase;
      color: #e15727;
      background-color: white;
      margin: 1rem;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #e15727;
      font-weight: bold;
      flex: 1;
      font-size: 10pt;

      img {
        width: 2.7rem;
      }

      &.appStore {
        img {
          // Apparently these values are consistent
          width: 82%;
        }
      }

      &.c1 * {
        max-width: 120px;
        height: auto;
      }

      &.c2 * {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

a {
  text-decoration: none;
  color: black;
}
